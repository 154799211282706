class LazyVideo {
  constructor (element) {
    this.isInitiated = false;
    this.youtubeContainer = $(element);
    this.playButton = this.youtubeContainer.find('.play-button');
    this.setupPrivacyChangeEvent();
    this.initIfApproved();
  }

  setupPrivacyChangeEvent () {
    const that = this;
    this.youtubeContainer.on('privacychange', function () {
      that.initIfApproved();
    });
  }

  initIfApproved () {
    if (this.isContentApproved() && !this.isInitiated) {
      this.init();
    }
  }

  isContentApproved () {
    return this.youtubeContainer.attr('data-privacy-content-approved') === 'approved';
  }

  init () {
    this.isInitiated = true;
    this.youtubeId = $(this.youtubeContainer).attr('data-embed');
    this.iframe = document.createElement('iframe');
    this.title = $(this.youtubeContainer).attr('data-title');
    this.createIframe();
    this.loadImage();
    this.setupListener();

    $('.nav-button').on('click', () => this.pauseVideo());
    $('.accessibility-navigation').on('click', () => this.pauseVideo());
    $('button').on('click', () => this.pauseVideo());
  }

  setupListener () {
    this.playButton.on('click keypress', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'click' || (e.type === 'keypress' && (e.keyCode === 32 || e.keyCode === 13))) {
        this.createVideo();
      }
    });
    this.youtubeContainer.on('click keypress', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'click' || (e.type === 'keypress' && (e.keyCode === 32 || e.keyCode === 13))) {
        this.createVideo();
      }
    });
  }

  createIframe () {
    this.iframe.setAttribute('frameborder', '0');
    this.iframe.setAttribute('allowfullscreen', '');
    this.iframe.setAttribute('class', 'embed-responsive-item');
    this.iframe.setAttribute('title', this.title);
    this.iframe.setAttribute(
      'src',
      `https://www.youtube.com/embed/${this.youtubeId}?enablejsapi=1&version=3&playerapiid=ytplayer&autoplay=1`
    );
  }

  /**
   * Create and append video container
   */
  createVideo () {
    this.hidePlayButton();
    this.pauseVideo();
    this.youtubeContainer.innerHTML = '';
    this.youtubeContainer.append(this.iframe);

    const container = this.youtubeContainer;
    const thisFunction = this;
    const videoPos = this.youtubeContainer.offset().top;

    // stop playing on scrolling
    $(window).on('scroll', function () {
      var containerHeight = container.height();
      if ($(this).scrollTop() >= (videoPos + containerHeight) || $(this).scrollTop() <= (videoPos + containerHeight)) {
        thisFunction.pauseVideo();
      }
    });
  }

  hidePlayButton () {
    this.playButton.attr('aria-expanded', 'true');
    this.playButton.css('z-index', '-1');
  }

  pauseVideo () {
    $('iframe').each(function () {
      $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    });
  }

  /**
   * Lazy Load Preview Thumbnails
   */
  loadImage () {
    const imageSource = `https://img.youtube.com/vi/${this.youtubeId}/hqdefault.jpg`;
    const image = new Image();
    image.src = imageSource;
    image.setAttribute('alt', this.title);
    image.addEventListener('load', () => {
      this.youtubeContainer.append(image);
    });
  }
}

export {LazyVideo};
export default LazyVideo;
