export class U016SingleSocialMediaPostFacebook {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.id = props.id;
  }

  addScript() {
    if (window.U016SingleSocialMediaPostFacebookScriptLoaded) {
      return;
    } else {
      window.U016SingleSocialMediaPostFacebookScriptLoaded = true;
    }

    //<script async defer crossorigin="anonymous" src="https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v20.0" nonce="hRqRjPbX"></script>

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("async", "");
    scriptEl.setAttribute("defer", "");
    scriptEl.setAttribute("crossorigin", "anonymous");
    scriptEl.setAttribute(
      "src",
      "https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v20.0"
    );
    scriptEl.setAttribute("nonce", "hRqRjPbX");

    document.body.appendChild(scriptEl);
  }

  clear() {
    let placeholders = document.querySelectorAll(
      ".ii-u016-social-media-feed .placeholder"
    );

    placeholders.forEach((placeholder) => {
      placeholder.style.display = "block";
    });
  }

  ready() {
    this.addScript();
  }
}

export class U016SingleSocialMediaPostInstagram {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.id = props.id;
  }

  addScript() {
    if (window.U016SingleSocialMediaPostInstagramScriptLoaded) {
      return;
    } else {
      window.U016SingleSocialMediaPostInstagramScriptLoaded = true;
    }

    //<script async src="//www.instagram.com/embed.js"></script>

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("async", "");
    scriptEl.setAttribute("src", "//www.instagram.com/embed.js");

    document.body.appendChild(scriptEl);
  }

  clear() {}

  ready() {
    this.addScript();
  }
}

export class U016SingleSocialMediaPostX {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.id = props.id;
  }

  addScript() {
    if (window.U016SingleSocialMediaPostXScriptLoaded) {
      return;
    } else {
      window.U016SingleSocialMediaPostXScriptLoaded = true;
    }

    //<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("async", "");
    scriptEl.setAttribute("src", "https://platform.twitter.com/widgets.js");
    scriptEl.setAttribute("charset", "utf-8");

    document.body.appendChild(scriptEl);
  }

  clear() {}

  ready() {
    this.addScript();
  }
}

export class U016SingleSocialMediaPostLinkedin {
  constructor(node, props) {
    this.node = node;
    this.props = props;
    this.id = props.id;
  }

  clear() {}

  ready() {}
}
